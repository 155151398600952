<template>
  <div>
    <header class="absolute top-0 left-0 right-0 z-10" :class="partnerLogoUrl ? 'bg-white' : 'bg-transparent'">
      <div class="standard-spacing flex items-center h-16 lg:h-24">
        <NuxtLink to="/" aria-label="Home">
          <HoapIcon icon="HoapLogo" :class="partnerLogoUrl ? 'text-black w-36' : 'text-white w-24'" />
        </NuxtLink>
        <button @click="showMobileNavMenu = true" class="ml-auto lg:hidden">
          <HoapIcon icon="Hamburger" class="w-8 text-white" />
        </button>
        <img v-if="partnerLogoUrl" :src="partnerLogoUrl" class="ml-auto h-3/4" />
        <div v-else class="items-center ml-auto hidden lg:flex">
          <HoapSecondaryButton
            v-for="navLink in links"
            :key="navLink.text"
            :label="navLink.text"
            :url="navLink.url"
            class="ml-4" />
          <HoapButton label="Book a demo" :url="config.public.calendlyURL" external class="ml-4" />
          <HoapButton :label="loginButton.text" inverted :url="loginButton.url" external class="ml-4" />
        </div>
      </div>
    </header>
    <div
      class="fixed inset-0 bg-black flex items-center transition-quick z-10"
      :class="showMobileNavMenu ? 'bg-opacity-75' : 'bg-opacity-0 pointer-events-none'"
      @click="showMobileNavMenu = false">
      <div v-if="showMobileNavMenu" class="w-full h-full standard-spacing py-8">
        <FocusTrap :returnFocusOnDeactivate="false" :active="showMobileNavMenu">
          <div class="relative h-full flex items-center justify-center">
            <button @click="showMobileNavMenu = false">
              <HoapIcon icon="Cross" class="w-8 text-white absolute top-0 right-0" />
            </button>
            <div>
              <div v-for="navLink in links" :key="navLink.text" class="w-full flex justify-center mb-4">
                <HoapSecondaryButton :label="navLink.text" :url="navLink.url" class="ml-4" />
              </div>
              <div class="flex items-center space-x-4 pt-4">
                <HoapButton label="Book a demo" :url="config.public.calendlyURL" external class="ml-4" />
                <HoapButton :label="loginButton.text" inverted external :url="loginButton.url" />
              </div>
            </div>
          </div>
        </FocusTrap>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { FocusTrap } from 'focus-trap-vue'
  import sitemapLinks from './sitemapLinks'

  const config = useRuntimeConfig()

  defineProps({
    partnerLogoUrl: String,
  })

  const links = sitemapLinks.filter((link) => !link.hideInNav)

  const loginButton = {
    text: 'Log in',
    url: 'https://app.hoap.com',
  }

  const showMobileNavMenu = ref(false)
</script>
