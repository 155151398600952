<template>
  <NuxtLink v-if="url" :to="url" :class="classes">
    {{ label }}
  </NuxtLink>
  <button v-else v-bind="$attrs" :class="classes">
    {{ label }}
  </button>
</template>

<script setup lang="ts">
  defineProps({
    label: {
      type: String,
      default: 'Read More',
    },
    url: String,
  })

  const classes = 'transition-quick border-b-2 text-white border-transparent hover:border-white py-2 text-sm'
</script>
